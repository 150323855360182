import { type Keywords } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import Link from 'next/link'
interface KeywordType {
  data: Keywords[]
}

export const Keyword = ({ data }: KeywordType) => {
  return (
    <>
      {data && data.length > 0 ? (
        <section className='container mx-auto py-8'>
          <div className='flex w-full flex-wrap'>
            <div className='mb-[20px] w-full text-base font-bold leading-tight text-dark-900'>
              Tìm kiếm nhiều nhất
            </div>
            <div className='flex w-full flex-wrap gap-2'>
              {data &&
                data.map((e, i) => {
                  return (
                    <Link
                      href={`/search?query=${encodeURIComponent(
                        getTranslate(e?.name)
                      )}`}
                      className='flex h-9 w-max cursor-pointer items-center rounded-full bg-white-200 px-3'
                      key={i}
                    >
                      {getTranslate(e.name)}
                    </Link>
                  )
                })}
            </div>
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  )
}

export default Keyword
