/* eslint-disable @typescript-eslint/no-explicit-any */
import { MegaMenu } from '@/components/MegaMenu/MegaMenu'
import { useStore } from '@/containers'
import { useTranslations } from 'next-intl'

const HeaderBottom = () => {
  const t = useTranslations('Header')
  const { megaMenu, quickMenu, topBanner } = useStore()

  return (
    <div
      className={`megaMenu sticky left-0 top-0 z-[999] bg-primary-base text-white shadow-megaMenu`}
    >
      <div className='container mx-auto'>
        <MegaMenu
          translateText={{
            allCate: t('allCate'),
            promotion: t('promotion'),
          }}
          megaMenuData={megaMenu}
          quickMenuData={quickMenu}
          topBanner={topBanner?.items || []}
        />
      </div>
    </div>
  )
}
export { HeaderBottom }
