import ImageOptimize from '@/components/ImageOptimize'
import { PageTypeSuffix } from '@/constants'

import { useNotification, useStore } from '@/containers'
import { getTranslate } from '@/utils/api-interceptors'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { v4 as uuidv4 } from 'uuid'

const ChooseProductCompare = dynamic(
  () => import('../components/Popup/ChooseProductCompare'),
  {
    ssr: false,
  }
)

const ComparePopup = dynamic(() => import('../components/Popup/ComparePopup'), {
  ssr: false,
})

const CompareTinyPopup = dynamic(
  () => import('../components/Popup/CompareTinyPopup'),
  {
    ssr: false,
  }
)

const OnDevelopPopup = dynamic(
  () => import('../components/Popup/OnDevelopPopup'),
  {
    ssr: false,
  }
)
type FooterProps = {
  page?: string
}
const Footer = ({ page }: FooterProps) => {
  const {
    bottomMenu,
    isOpenComingSoon,
    setIsOpenComingSoon,
    isOpenAddComparePopup,
    setProductCompares,
    productCompares,
    setIsOpenAddComparePopup,
    isOpenComparePopup,
    setIsOpenComparePopup,
    productViewed,
    catViewId,
  } = useStore()
  const { systemConfig } = useNotification()

  const t = useTranslations('Footer')
  return (
    <footer id='footer' className={`mx-auto w-full`}>
      {page === 'product-detail' && (
        <>
          <ComparePopup
            open={isOpenComparePopup}
            setIsOpenComparePopup={setIsOpenComparePopup}
            setProductCompares={setProductCompares}
            setIsOpenAddComparePopup={setIsOpenAddComparePopup}
            productCompares={productCompares}
          />

          <ChooseProductCompare
            catViewId={catViewId}
            productViewed={productViewed}
            isOpen={isOpenAddComparePopup}
            setProductCompares={setProductCompares}
            productCompares={productCompares}
            setIsOpen={setIsOpenAddComparePopup}
          ></ChooseProductCompare>

          <CompareTinyPopup
            isOpen={!isOpenComparePopup}
            productCompares={productCompares.filter(e => e._id).length}
            setIsOpen={setIsOpenComparePopup}
          ></CompareTinyPopup>
        </>
      )}

      <OnDevelopPopup
        isOpen={isOpenComingSoon}
        setIsOpen={setIsOpenComingSoon}
      />

      <section className={`bg-white pb-4 pt-8`}>
        <div className={`container mx-auto flex justify-start`}>
          {bottomMenu &&
            bottomMenu.length > 0 &&
            bottomMenu.map(e => (
              <div className='max-w-[25%] flex-4 text-dark-400' key={uuidv4()}>
                <h5 className='mb-3 text-fieldLabel font-bold'>
                  {getTranslate(e.name)}
                </h5>
                {e.children && e.children.length > 0 && (
                  <ul>
                    {e.children.map(f => (
                      <li key={uuidv4()} className='mb-[4px] flex items-start'>
                        {f.type === 'link' ? (
                          <Link href={`${f.url}` || ''}>
                            {getTranslate(f.name)}
                          </Link>
                        ) : (
                          <Link
                            href={`/${f.slug + PageTypeSuffix.PAGE_CMS}` || ''}
                          >
                            {getTranslate(f.name)}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
        </div>
      </section>

      <section
        className={`footerInfo border-t-[1px] border-neutral-100 pb-4 pt-5`}
      >
        <div
          className={`container mx-auto flex items-start justify-between text-dark-500`}
        >
          <div className='max-w-[45%] flex-[0_0_45%]'>
            <div className='aboutUs mb-2 flex content-end items-center gap-2'>
              <span className='mt-0.5 text-base font-medium leading-tight text-dark-400'>
                THÔNG TIN VỀ
              </span>
              <ImageOptimize
                src={systemConfig?.companyLogo || ''}
                alt={getTranslate(systemConfig?.companyName) || ''}
                className='h-auto max-h-4 w-auto max-w-[100px]'
              />
            </div>
            <div className='hotLine flex items-start justify-start gap-3'>
              <div className='mb-1 flex flex-col flex-wrap'>
                <p className='mb-1 block text-sm font-bold text-dark-400'>
                  {systemConfig?.companyPhoneTitle || 'Tư vấn mua hàng'}:
                </p>

                {systemConfig?.companyPhone && (
                  <div className='flex items-center gap-1 leading-none'>
                    <p className='text-2xl font-semibold text-dark-900'>
                      {systemConfig?.companyPhone}
                    </p>
                    <p className='text-sm italic text-gray-800'>
                      {systemConfig?.companyLabelPhone}
                    </p>
                  </div>
                )}
              </div>
              <div className='mb-1 flex flex-col flex-wrap'>
                <p className='mb-1 block text-sm font-bold text-dark-400'>
                  {systemConfig?.companyPhoneTitle2 || 'Bảo hành'}:
                </p>
                {systemConfig?.companyPhone2 && (
                  <div className='flex items-center gap-1 leading-none'>
                    <p className='text-2xl font-semibold text-dark-900'>
                      {systemConfig?.companyPhone2}
                    </p>
                    <p className='text-sm italic text-gray-800'>
                      {systemConfig?.companyLabelPhone2}
                    </p>
                  </div>
                )}
                <ul className='mt-1 text-sm font-light text-dark-400'>
                  {/* <li>
                    <span>Website: </span>
                    <span>{systemConfig?.companyWebsite}</span>
                  </li> */}
                  <li>
                    <span>Email: </span>
                    <span>{systemConfig?.companyEmail}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='max-w-[50%] flex-50 text-right'>
            <div className='flex flex-col flex-wrap justify-end'>
              <ul className='mb-0 flex items-center justify-end'>
                <li className='ml-1.5'>
                  <Link
                    href={systemConfig?.companyWebsite || '/'}
                    target='blank'
                    className='flex h-[36px] w-[36px] items-center justify-center rounded-full border border-dark-200'
                  >
                    <img
                      src='/assets/icons/footer/web.svg'
                      alt='Facebook'
                      className='h-[20px] w-[20px]'
                    />
                  </Link>
                </li>
                <li className='ml-1.5'>
                  <Link
                    href={systemConfig?.companyFacebook || '/'}
                    target='blank'
                    className='flex h-[36px] w-[36px] items-center justify-center rounded-full border border-dark-200'
                  >
                    <img
                      src='/assets/icons/footer/fb.svg'
                      alt='Facebook'
                      className='h-[20px] w-[20px]'
                    />
                  </Link>
                </li>
                <li className='ml-1.5'>
                  <Link
                    href={systemConfig?.companyYoutube || '/'}
                    className='flex h-[36px] w-[36px] items-center justify-center rounded-full border border-dark-200'
                    target='blank'
                  >
                    <img
                      src='/assets/icons/footer/youtube.svg'
                      alt='Youtube'
                      className='h-[20px] w-[20px]'
                    />
                  </Link>
                </li>
                <li className='ml-1.5'>
                  <Link
                    href={systemConfig?.companyTikTok || '/'}
                    target='blank'
                    className='flex h-[36px] w-[36px] items-center justify-center rounded-full border border-dark-200'
                  >
                    <img
                      src='/assets/icons/footer/tiktok.svg'
                      alt='Tiktok'
                      className='h-[20px] w-[20px]'
                    />
                  </Link>
                </li>
              </ul>
              {systemConfig?.announcedMOIT && systemConfig?.companyVerify && (
                <Link
                  href={systemConfig?.companyVerify || ''}
                  title='Đã thông báo'
                  target='blank'
                  className='ml-auto mt-1 block'
                >
                  <ImageOptimize
                    width={170}
                    height={58}
                    src={systemConfig?.announcedMOIT || ''}
                    className='ml-auto h-auto'
                    alt='Đã thông báo'
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>
      <section
        className={`footerInfo border-t-[1px] border-neutral-100 pb-4 pt-3`}
      >
        <div
          className={`container mx-auto flex items-end justify-between text-dark-500`}
        >
          <div className='max-w-[50%] flex-50'>
            <ul className='text-sm leading-relaxed'>
              <li>
                <p className='text-base font-semibold text-dark-400'>
                  {getTranslate(systemConfig?.companyName)}
                </p>
              </li>
              <li>
                <span>{t('businessRegisterCertificate')}: </span>
                <span>{getTranslate(systemConfig?.businessLicense)}</span>
              </li>
              <li>
                <span>{t('address')}: </span>
                <span>{getTranslate(systemConfig?.companyAddress)}</span>
              </li>
            </ul>
          </div>
          <div className='copyright max-w-[50%] flex-50 text-right'>
            <p className='mb-1.5 text-sm font-normal italic leading-tight text-dark-500'>
              {getTranslate(systemConfig?.companyLicense)}
            </p>
          </div>
        </div>
      </section>
    </footer>
  )
}

export { Footer }
