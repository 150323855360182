import { useEffect, useRef, useState } from 'react'

export const useClickOutsideComponent = (
  initialIsVisible: boolean,
  hideOnScroll?: boolean
) => {
  const [isComponentVisible, setIsComponentVisible] =
    useState<boolean>(initialIsVisible)
  const ref = useRef<HTMLInputElement | null>(null)

  const handleClickOutside = (event: DocumentEventMap['click']) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!ref?.current?.contains(event?.target)) {
      setIsComponentVisible(false)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleScroll = (_event: DocumentEventMap['wheel']) => {
    if (hideOnScroll) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)

    if (hideOnScroll) {
      document.addEventListener('wheel', handleScroll, {
        passive: true,
        capture: true,
      })
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, true)

      if (hideOnScroll)
        document.removeEventListener('wheel', handleScroll, true)
    }
  }, [])

  return { ref, isComponentVisible, setIsComponentVisible }
}
