import { MenuType, PageTypeSuffix } from '@/constants'
import { useNotification } from '@/containers'
import { type Menu } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { IconPromotion } from '../Icon/HeaderIcon'
import ImageOptimize from '../ImageOptimize'

interface QuickMenuProps {
  QuickMenuData: Menu[]
  translateText: {
    allCate: string
    promotion: string
  }
}

export const QuickMenu = ({ QuickMenuData, translateText }: QuickMenuProps) => {
  const { asPath } = useRouter()
  const { systemConfig } = useNotification()
  const checkActive = (item: string) => {
    if (asPath == item) {
      return 'text-dark-900'
    }
  }
  return (
    <div className='flex w-full justify-between'>
      <div className={`relative`} id='menuBoxScroll'>
        <div className='no-scrollbar relative z-10 flex h-full w-full max-w-[840px] items-center gap-x-8 overflow-auto whitespace-nowrap pr-12 text-white '>
          {QuickMenuData &&
            QuickMenuData.map((item, index) => {
              return (
                <Link
                  href={
                    item.type === MenuType.PRODUCT_CAT
                      ? `/${item.slug + PageTypeSuffix.CATEGORY}`
                      : item.url || '/'
                  }
                  key={index}
                  className={`flex gap-1.5 text-base font-normal hover:text-dark-900 ${checkActive(
                    '/' + item.slug + PageTypeSuffix.CATEGORY
                  )}`}
                >
                  {item.image && (
                    <Image
                      width={24}
                      height={24}
                      alt={getTranslate(item.name) || ''}
                      src={item.image}
                      className='h-4 w-4'
                      priority
                    />
                  )}
                  <span>{getTranslate(item.name)}</span>
                </Link>
              )
            })}
        </div>
      </div>
      <div className='border-black flex gap-3'>
        {systemConfig?.ctaButtonMenu ? (
          <Link
            className='max-w flex h-9 cursor-pointer items-center gap-[2px] overflow-hidden rounded-lg px-2'
            href={systemConfig?.ctaButtonUrl || ''}
            style={{ backgroundColor: systemConfig?.ctaButtonBgColor }}
          >
            <ImageOptimize
              width={24}
              height={24}
              alt={'Khuyến mãi'}
              src={systemConfig?.ctaButtonIcon || ''}
              className='h-4 w-4'
            />

            <span className='ml-[6px] text-base text-white'>
              {systemConfig?.ctaButtonText}
            </span>
            <span className='ml-[3px] text-base font-semibold text-white'>
              {systemConfig?.ctaButtonNumber}
            </span>
          </Link>
        ) : (
          ''
        )}
        <Link
          className='max-w flex h-9 cursor-pointer items-center overflow-hidden rounded-lg'
          href='/hot-deal'
        >
          {systemConfig?.promotionImage ? (
            <ImageOptimize
              width={160}
              height={32}
              alt={'Khuyến mãi'}
              src={systemConfig?.promotionImage || ''}
              className='h-full w-auto'
            />
          ) : (
            <div className='flex h-full w-full items-center justify-between gap-1.5 bg-primary-second px-2'>
              <IconPromotion />
              <span className='ml-[5px] text-base text-white'>
                {translateText.promotion}
              </span>
            </div>
          )}
        </Link>
      </div>
    </div>
  )
}
