import { ArrowUp } from '../Icon/ArrowUp'

const ButtonScrollToTop = ({ className = '' }: { className?: string }) => {
  const isBrowser = () => typeof window !== 'undefined'
  const goTop = () => {
    if (!isBrowser()) return
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  return (
    <div
      className={`${className} fixed bottom-6 right-[12px] z-[9999] flex h-12 w-12 flex-col items-center justify-center rounded-full bg-white shadow-xl transition-all duration-200 ease-in-out`}
    >
      <div
        onClick={() => goTop()}
        className='flex w-full cursor-pointer flex-col items-center justify-center text-center'
      >
        <ArrowUp />
      </div>
    </div>
  )
}
export default ButtonScrollToTop
