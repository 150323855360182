export const CompareAddIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='7.78125' cy='8.06201' r='6.75' stroke='#4294FE' />
      <path
        d='M11.4735 7.35888C11.8624 7.35897 12.1767 7.67324 12.1767 8.06217C12.1767 8.25613 12.0982 8.43216 11.9708 8.55955C11.8435 8.68686 11.6674 8.76544 11.4735 8.76544L8.48451 8.76531L8.48463 11.7543C8.48464 11.9482 8.40605 12.1243 8.27875 12.2516C8.15135 12.379 7.97533 12.4576 7.78136 12.4576C7.39243 12.4575 7.07817 12.1432 7.07807 11.7543L7.07813 8.7653L4.08916 8.76535C3.70023 8.76526 3.38596 8.45099 3.38587 8.06206C3.38595 7.67313 3.70021 7.35887 4.08914 7.35879L7.07811 7.35892L7.07798 4.36995C7.07807 3.98102 7.39232 3.66676 7.78125 3.66667C8.17019 3.66677 8.48445 3.98103 8.48454 4.36996L8.48449 7.35893L11.4735 7.35888Z'
        fill='#4294FE'
      />
    </svg>
  )
}
