import FormGroup from '@/components/Form/FormGroup'
import { HeaderLogo } from '@/components/HeaderLogo'
import { AccountTrigger } from '@/components/Icon/AccountTrigger'
import { IconLocation, IconsDownsLocate } from '@/components/Icon/HeaderIcon'
import { SearchBar } from '@/components/SearchBar'
import { TopHeader } from '@/components/TopHeader'
import { useAuth, useStore } from '@/containers'
import { IconCartHome, IconDelivery } from '@/styles/Icon'
import { type UserAddress } from '@/types'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'

interface HeaderLogo {
  label: JSX.Element | null
  key: number
}
const AddAddressShipping = dynamic(
  () => import('@/components/Popup/AddAddressShipping'),
  {
    ssr: false,
  }
)
const ListAddressShipping = dynamic(
  () => import('@/components/Popup/ListAddressShipping'),
  {
    ssr: false,
  }
)
type HeaderProps = {
  page?: string
}
const Header = ({ page }: HeaderProps) => {
  const t = useTranslations('Header')
  const {
    user,
    logout,
    cart,
    userAddress,
    isOpenAddAddress,
    isOpenListAddress,
    setIsOpenListAddress,
    setIsOpenAddAddress,
  } = useAuth()
  const { regions, saleRegion, topBanner, setIsOpenPopupLocation } = useStore()
  const [address, setAddress] = useState<UserAddress>()
  const lastName = user?.name.split(' ').slice(-1).join(' ')
  const router = useRouter()
  const renderInput = useMemo(() => {
    const store = regions.find(e => e.code === saleRegion)

    return (
      <div
        onClick={() => setIsOpenPopupLocation(true)}
        className='flex h-12 w-[160px] max-w-[160px] cursor-pointer items-center gap-2 rounded-lg border border-solid border-gray-300 px-1 leading-tight'
      >
        <div className='w-4'>
          <IconLocation />
        </div>

        <div className='flex flex-wrap text-sm text-dark-900'>
          <p className='w-full'>Xem giá, tồn tại:</p>
          <div className='flex items-center gap-1'>
            <span className='line-clamp-1 font-bold'>{store?.name}</span>
            <IconsDownsLocate />
          </div>
        </div>
      </div>
    )
  }, [regions, saleRegion])
  return (
    <header className='relative z-[1099] bg-white-500'>
      {topBanner ? <TopHeader topBanner={topBanner} /> : ''}
      <div className={`container mx-auto py-2`}>
        <div className={`flex items-center`}>
          {page && page == 'homepage' ? (
            <h1 className='max-w-[180px] flex-[0_0_180px] justify-center'>
              <HeaderLogo />
            </h1>
          ) : (
            <div className='max-w-[180px] flex-[0_0_180px] justify-center'>
              <HeaderLogo />
            </div>
          )}

          <div className='flex h-full flex-1 items-center justify-end text-sm text-dark-900 md:gap-2 xl:gap-4'>
            {renderInput}
            <FormGroup className='relative flex flex-row'>
              <SearchBar />
            </FormGroup>
            <div className='h-full items-center py-1 leading-4'>
              {user ? (
                <Link
                  href={'/orders'}
                  className='flex h-full items-center gap-2'
                >
                  <IconDelivery />
                  <span className='max-w-[75px]'>{t('searchOrder')}</span>
                </Link>
              ) : (
                <Link
                  href={'/search-order'}
                  className='flex h-full items-center gap-2'
                >
                  <IconDelivery />
                  <span className='max-w-[75px]'>{t('searchOrder')}</span>
                </Link>
              )}
            </div>
            <div className='h-full items-center py-1'>
              <Link
                href='/checkout/cart'
                className='relative flex h-full items-center gap-2'
              >
                <IconCartHome />
                <p className='absolute -top-2.5 left-3 z-[9999] flex h-[20px] w-[20px] items-center justify-center rounded-[50%] bg-primary-second text-center text-xs leading-none text-white'>
                  {cart?.quantity && cart.quantity > 9 ? '9+' : cart.quantity}
                </p>

                <span className=''>{t('cart')}</span>
              </Link>
            </div>
            <div className='h-full items-center py-1'>
              {user ? (
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger asChild>
                    <div className='relative flex items-center justify-center gap-1'>
                      <AccountTrigger />
                      <span className='hover:cursor-pointer'>
                        {t('sayHi')}, {lastName}{' '}
                      </span>
                      <svg
                        className='relative left-[-2px]'
                        xmlns='http://www.w3.org/2000/svg'
                        width='8'
                        height='7'
                        viewBox='0 0 8 7'
                        fill='none'
                      >
                        <path
                          d='M4 6.56494L0.535899 0.564941L7.4641 0.564941L4 6.56494Z'
                          fill='#303538'
                        />
                      </svg>
                    </div>
                  </DropdownMenu.Trigger>

                  <DropdownMenu.Portal>
                    <DropdownMenu.Content
                      className='DropdownLanguageContent z-[99999] overflow-hidden'
                      sideOffset={15}
                      side='bottom'
                      align='end'
                      sticky='always'
                    >
                      <DropdownMenu.Item
                        onClick={() => router.push('/account?tab=account')}
                        className='DropdownAccountItem border-dark-1200 flex h-[40px] cursor-pointer  items-center border-t-[1px] text-sm font-normal text-dark-400 outline-none first:border-t-[0px] hover:bg-sky-100 hover:text-blue-600'
                      >
                        <span className='px-3'>Thông tin tài khoản</span>
                      </DropdownMenu.Item>
                      <DropdownMenu.Item
                        onClick={() => setIsOpenListAddress(true)}
                        className='DropdownAccountItem border-dark-1200 flex h-[40px] cursor-pointer  items-center border-t-[1px] text-sm font-normal text-dark-400 outline-none first:border-t-[0px] hover:bg-sky-100 hover:text-blue-600'
                      >
                        <span className='px-3'>Địa chỉ giao hàng</span>
                      </DropdownMenu.Item>
                      <DropdownMenu.Item
                        onClick={() => logout()}
                        className='DropdownAccountItem border-dark-1200 flex h-[40px] cursor-pointer  items-center border-t-[1px] text-sm font-normal text-dark-400 outline-none first:border-t-[0px] hover:bg-sky-100 hover:text-blue-600'
                      >
                        <span className='px-3'>Đăng xuất</span>
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu.Portal>
                </DropdownMenu.Root>
              ) : (
                <Link
                  href='/login'
                  className='flex items-center justify-center gap-x-[12px]'
                >
                  <AccountTrigger />
                  <span>{t('login')}</span>
                </Link>
              )}
            </div>

            <ListAddressShipping
              handleSelect={e => {
                setAddress(e)
                setIsOpenListAddress(false)
                setIsOpenAddAddress(true)
              }}
              open={isOpenListAddress}
              onClose={() => {
                setIsOpenListAddress(false)
              }}
              handleAdd={() => {
                setIsOpenListAddress(false)
                setIsOpenAddAddress(true)
              }}
              address={userAddress}
            />
            <AddAddressShipping
              userAddress={address}
              open={isOpenAddAddress}
              onClose={() => {
                setIsOpenAddAddress(false)
                setIsOpenListAddress(true)
              }}
            />
          </div>
        </div>
      </div>
    </header>
  )
}
export { Header }
