import { Languages, LocalStorageKeys, PageTypeSuffix } from '@/constants'
import { useNotification } from '@/containers'
import { useDebounce } from '@/hooks/useDebounce'
import { type Category, type Product } from '@/types'
import { api } from '@/utils'
import { getTranslate } from '@/utils/api-interceptors'
import { getCategories, getProductWithPaginate } from '@/utils/endpoint'
import { useClickOutsideComponent } from '@/utils/useClickOutsideComponent'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { SearchProductItemCard } from '../SearchProductItemCard'

export const SearchBar = () => {
  const maxItemsReached = 3
  const t = useTranslations('Header')
  const { systemConfig } = useNotification()
  const [searchBarValue, setSearchBarValue] = useState('')
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickOutsideComponent(false)
  const router = useRouter()
  const debouncedSearchBarValue = useDebounce(searchBarValue, 500)

  const [displayResult, setDisplayResult] = useState<{
    products: Product[] | null | undefined
    categories: Category[] | null | undefined
  } | null>(null)

  const handleInputKeyUp = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (searchBarValue === '') {
        localStorage?.removeItem(LocalStorageKeys.ITEM_LISTING_SEARCH)
      }
    }
    if (event.key === 'Enter') {
      const res = await api(`api/keyword-cat?keyword=${searchBarValue}`, {
        method: 'get',
        baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
      })
      if (res && !(res instanceof Error) && res.data) {
        router.push(
          getTranslate(res?.data?.slugs?.value) +
            PageTypeSuffix.CATEGORY +
            `?query=${searchBarValue}`
        )
      } else {
        return router.push(`/search?query=${searchBarValue}`)
      }
    }
  }

  useEffect(() => {
    if (router.query && router.query.query) {
      setSearchBarValue(router.query.query as string)
    }
  }, [router.query])

  useEffect(() => {
    const query = router.query
    if (debouncedSearchBarValue && router.query) {
      Promise.all([
        getProductWithPaginate({
          ...query,
          fullTextSearch: debouncedSearchBarValue,
          limit: maxItemsReached,
          sortBy: 'score',
          sortType: 'desc',
        }).then(res => res?.data),
        getCategories({
          ...query,
          fullTextSearch: debouncedSearchBarValue,
          limit: maxItemsReached,
          sortBy: 'score',
          sortType: 'desc',
        }).then(res => res?.data),
      ]).then(res => {
        const [products, categories] = res
        setDisplayResult({
          products,
          categories,
        })
      })
    } else {
      setDisplayResult(null)
    }
  }, [debouncedSearchBarValue])

  const EmptyMessage = () => (
    <h3 className='EmptyMessage flex h-[48px] w-full items-center bg-dark-200 pl-2 text-Headline text-dark-400'>
      {t('searching')}
    </h3>
  )

  return (
    <>
      <div className='relative flex'>
        <input
          type='text'
          className={`placeholder:text- !h-12 w-full rounded-lg p-3 pl-12 text-base placeholder:text-dark-400 hover:cursor-pointer focus:shadow-none focus:outline-none focus:ring-0 md:min-w-[300px] xl:min-w-[383px]`}
          placeholder={t('searchPld', {
            brand: systemConfig?.websiteBrandName,
          })}
          value={searchBarValue}
          onChange={e => {
            setSearchBarValue(e.target.value)
            setIsComponentVisible(true)
          }}
          onKeyUp={e => handleInputKeyUp(e)}
        />
        <span
          className='absolute left-1.5 top-0 flex h-[48px] min-w-[42px] cursor-pointer items-center justify-center p-0'
          // onClick={() => {
          //   if (searchBarValue !== '')
          //     return router.push(`/search?query=${searchBarValue}`)
          // }}
        >
          <svg
            width={25}
            height={26}
            viewBox='0 0 25 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#clip0_4020_2263)'>
              <path
                d='M18.3469 16.6375C19.8626 14.5652 20.5404 11.9971 20.2448 9.44669C19.9491 6.89626 18.7017 4.55137 16.752 2.88079C14.8024 1.21021 12.294 0.337018 9.72838 0.435771C7.16276 0.534523 4.72893 1.59794 2.91343 3.41345C1.09792 5.22896 0.0344968 7.66279 -0.0642558 10.2284C-0.163008 12.794 0.710183 15.3024 2.38077 17.2521C4.05135 19.2018 6.39623 20.4491 8.94666 20.7448C11.4971 21.0405 14.0652 20.3627 16.1375 18.8469L22.3328 25.0422C22.6275 25.3268 23.0222 25.4843 23.4319 25.4808C23.8416 25.4772 24.2335 25.3129 24.5232 25.0232C24.8129 24.7335 24.9772 24.3416 24.9807 23.9319C24.9843 23.5222 24.8268 23.1275 24.5422 22.8328L18.3469 16.6375ZM3.125 10.6563C3.125 9.26563 3.53738 7.90621 4.30998 6.74992C5.08258 5.59364 6.18072 4.69243 7.46551 4.16025C8.7503 3.62807 10.1641 3.48883 11.528 3.76013C12.8919 4.03143 14.1448 4.70109 15.1281 5.68443C16.1114 6.66777 16.7811 7.92062 17.0524 9.28455C17.3237 10.6485 17.1845 12.0622 16.6523 13.347C16.1201 14.6318 15.2189 15.7299 14.0626 16.5025C12.9063 17.2752 11.5469 17.6875 10.1563 17.6875C8.29208 17.6855 6.50486 16.944 5.18669 15.6258C3.86852 14.3077 3.12707 12.5204 3.125 10.6563Z'
                fill='#00C1D5'
              />
            </g>
            <defs>
              <clipPath id='clip0_4020_2263'>
                <rect
                  width={25}
                  height={25}
                  fill='white'
                  transform='translate(0 0.5)'
                />
              </clipPath>
            </defs>
          </svg>
        </span>
      </div>
      {/* RESULTS */}
      {isComponentVisible && searchBarValue.length > 0 && (
        <div
          className='absolute left-0 top-0 z-[1099] mt-14 w-[383px] overflow-hidden rounded-lg bg-white shadow-dark-400'
          ref={ref}
        >
          {displayResult ? (
            <>
              <div className='searchProductResult w-full'>
                <h3 className='searchProductResultHeader flex h-[48px] w-full items-center bg-white pl-2 text-Headline text-dark-400'>
                  {t('productSuggest')}
                </h3>
                <div className='searchProductResultContent grid'>
                  {displayResult.products?.map(el => (
                    <Link
                      onClick={() => setIsComponentVisible(false)}
                      key={el._id}
                      href={
                        '/' + getTranslate(el.slugs.value) + el.slugs.postfix
                      }
                      className='block'
                    >
                      <SearchProductItemCard
                        id={el._id}
                        price={el.finalPrice
                          .toLocaleString(Languages.VN, {
                            style: 'currency',
                            currency: 'VND',
                          })
                          .replace(/\s₫/g, 'đ')}
                        name={getTranslate(el.name)}
                        imgSrc={el?.thumbnail}
                        imgAlt={getTranslate(el.name)}
                      />
                    </Link>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <EmptyMessage />
            </>
          )}
        </div>
      )}
    </>
  )
}
