import type { BannerList, TopHeaderSlider } from '@/types'
import Link from 'next/link'
import { useEffect, useRef, useState, type FC } from 'react'
import type { Settings } from 'react-slick'
import Slider from 'react-slick'
import ImageOptimize from '../ImageOptimize'
interface Props {
  topBanner: BannerList
}
export const TopHeader: FC<Props> = ({ topBanner }: Props) => {
  const [bgColor, setBgColor] = useState(
    topBanner?.items[0]?.bgColor || 'unset'
  )
  function NextArrow() {
    return (
      <div
        className={`arrowSlickCs slickNext`}
        onClick={() => slider?.current?.slickNext()}
      >
        <img
          src='/assets/icons/arrow/slick-arrow.svg'
          alt='Next'
          className={''}
        />
      </div>
    )
  }

  function PrevArrow() {
    return (
      <div
        className={`arrowSlickCs slickPrev`}
        onClick={() => slider?.current?.slickPrev()}
      >
        <img
          src='/assets/icons/arrow/slick-arrow.svg'
          alt='Next'
          className={'rotate-180'}
        />
      </div>
    )
  }

  const sliderSettings: Settings = {
    autoplay: true,
    dots: false,
    autoplaySpeed: 5000,
    speed: 800,
    arrows: true,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  const slider = useRef<Slider>(null)

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (bgColor && ref && ref.current) {
      ref.current.style.backgroundColor = bgColor
    }
  }, [bgColor, ref])

  return (
    <section ref={ref} className={`topBanner h-[56px]`}>
      <div className={`container mx-auto`}>
        <Slider
          beforeChange={(current, nextSlide) => {
            setBgColor(topBanner?.items[nextSlide]?.bgColor)
          }}
          ref={slider}
          {...sliderSettings}
          className='max-w-screen flex'
        >
          {topBanner?.items &&
            topBanner?.items.map((slider: TopHeaderSlider, index: number) => (
              <Link href={slider.link || '/'} key={index}>
                <ImageOptimize
                  className='h-auto max-h-[56px] w-full object-contain'
                  src={slider.image}
                  alt={slider.title}
                  width={1366}
                  height={44}
                  priority
                />
              </Link>
            ))}
        </Slider>
      </div>
    </section>
  )
}
